var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useAsync } from "react-async-hook";
import requestJson from "./requestJson";
import ImageGallery from "react-image-gallery";
import "./DAPImageGallery.scss";
/**
 * Get all possible image thumbnails for a file.
 * The result array in order of from small size to full size.
 *
 * @param {File} file
 * @return {*}  {ImageItem[]}
 */
function getImages(file) {
    const thumbnails = [];
    if (file === null || file === void 0 ? void 0 : file.smallThumbnail) {
        thumbnails.push({
            label: "Small",
            url: file.smallThumbnail
        });
    }
    if (file === null || file === void 0 ? void 0 : file.mediumThumbnail) {
        thumbnails.push({
            label: "Medium",
            url: file.mediumThumbnail
        });
    }
    if (file === null || file === void 0 ? void 0 : file.largeThumbnail) {
        thumbnails.push({
            label: "Large",
            url: file.largeThumbnail
        });
    }
    if (file === null || file === void 0 ? void 0 : file.fullThumbnail) {
        thumbnails.push({
            label: "Full",
            url: file.fullThumbnail
        });
    }
    return thumbnails;
}
/**
 * Pick two images from all possible images
 * One for the smallest size (use as thumbnail).
 * One used for full size preview.
 *
 * @param {ImageItem[]} images
 * @return {*}  {ImageItem[]}
 */
function pick2Images(images) {
    if ((images === null || images === void 0 ? void 0 : images.length) >= 2) {
        return [images[0], images[images.length - 1]];
    }
    else if (images.length > 0) {
        return [images[0], images[0]];
    }
    else {
        return [];
    }
}
const DAPImageGallery = (props) => {
    var _a;
    const { dataset, config, distributionId } = props;
    const { identifier: datasetId } = dataset;
    const { registryApiReadOnlyBaseUrl } = config;
    if (((_a = dataset.sourceDetails) === null || _a === void 0 ? void 0 : _a.id) !== "dap") {
        return null;
    }
    const { result: galleryItems, loading, error } = useAsync((datasetId, distributionId) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f;
        const dapDatasetData = yield requestJson(`${registryApiReadOnlyBaseUrl}records/${encodeURIComponent(datasetId)}/aspects/dap-dataset`);
        if (!dapDatasetData.data) {
            throw new Error("Cannot locate data files.");
        }
        const collectionFilesData = yield requestJson(config.proxyUrl + "_0d/" + dapDatasetData.data);
        let files = collectionFilesData === null || collectionFilesData === void 0 ? void 0 : collectionFilesData.file;
        if (distributionId) {
            // on distribution page, we only show one item for current file.
            const fileId = (_d = (_c = (_b = dataset.distributions) === null || _b === void 0 ? void 0 : _b.find((dis) => dis.identifier === distributionId)) === null || _c === void 0 ? void 0 : _c.identifier) === null || _d === void 0 ? void 0 : _d.replace(/[^0-9]/g, "");
            files = files === null || files === void 0 ? void 0 : files.filter((item) => String(item.id) == fileId);
        }
        const galleryItems = (_f = (_e = files === null || files === void 0 ? void 0 : files.filter((item) => item.smallThumbnail ||
            item.mediumThumbnail ||
            item.largeThumbnail ||
            item.largeThumbnail)) === null || _e === void 0 ? void 0 : _e.map((file) => {
            const images = pick2Images(getImages(file));
            if (!(images === null || images === void 0 ? void 0 : images.length)) {
                return null;
            }
            const originalTitle = `${file.filename} (${images[1].label} size)`;
            const thumbnailTitle = `${file.filename} (${images[0].label})`;
            const galleryItem = {
                original: images[1].url,
                thumbnail: images[0].url,
                originalTitle,
                thumbnailTitle,
                originalAlt: originalTitle,
                thumbnailAlt: thumbnailTitle,
                description: originalTitle,
                originalHeight: "688px",
                originalWidth: "688px",
                thumbnailWidth: "92px",
                thumbnailHeight: "92px",
                loading: "lazy",
                thumbnailLoading: "lazy"
            };
            return galleryItem;
        })) === null || _f === void 0 ? void 0 : _f.filter((item) => !!item);
        return (galleryItems === null || galleryItems === void 0 ? void 0 : galleryItems.length) ? galleryItems : [];
    }), [datasetId, distributionId]);
    return (React.createElement("div", { className: "no-print magda-plugin-component-dap-thumbnail-viewer" },
        React.createElement("h3", { className: "section-heading" }, "Image Gallery"),
        loading ? ("Loading...") : error ? (React.createElement("p", null,
            "Error: ",
            String(error))) : !(galleryItems === null || galleryItems === void 0 ? void 0 : galleryItems.length) ? ("No preview image available.") : (React.createElement(ImageGallery, { items: galleryItems, infinite: false, showIndex: true, lazyLoad: true, showPlayButton: false, thumbnailPosition: "bottom", showNav: true, useTranslate3D: true }))));
};
export default DAPImageGallery;
